const locationMapModal = (() => {
  const pageLocation = (document.getElementById("pageLocation") != null);
  if (pageLocation) {



    MicroModal.init({
      disableScroll: true,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
      onShow: modal => console.info(`${modal.id} is shown`), // [1]
      onClose: modal => console.info(`${modal.id} is hidden`), // [2]
    });



  }
})()
export default locationMapModal;