const topMain = (() => {
  const pageTop = (document.getElementById("pageTop") != null);
  if (pageTop) {



    // let secMain = document.getElementById('jsSecMain');
    // let mainWrap = secMain.querySelector('.js-main-wrap');

    // let sW = window.innerWidth;
    // let video
    // if (sW > 750) {
    //   video = mainWrap.querySelector('.js-video-pc');
    // } else {
    //   video = mainWrap.querySelector('.js-video-sp');
    // }





  }
})()
export default topMain;