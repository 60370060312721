const comVideoModal = (() => {
  const jsComSecMovieModal = (document.getElementById("jsComSecMovieModal") != null);
  if (jsComSecMovieModal) {



    MicroModal.init({
      disableScroll: true,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
      onShow: modal => {
        // console.info(`${modal.id} is shown`)
        let div = document.querySelector(`#${modal.id}`);
        let video = div.querySelector('video');
        video.play()
      },
      onClose: modal => {
        // console.info(`${modal.id} is hidden`)
        let div = document.querySelector(`#${modal.id}`);
        let video = div.querySelector('video');
        video.pause()
      },
    });



  }
})()
export default comVideoModal;