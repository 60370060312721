import {
  funcScrollControl,
  funcLoad,
  comMain,
} from './index';

const funcEntry = (() => {


  funcScrollControl.noScroll();

  const pageTop = document.getElementById('pageTop');

  if (pageTop) {

    let secMain = document.getElementById('jsSecMain');
    let mainWrap = secMain.querySelector('.js-main-wrap');

    let sW = window.innerWidth;
    let video
    if (sW > 750) {
      video = mainWrap.querySelector('.js-video-pc');
    } else {
      video = mainWrap.querySelector('.js-video-sp');
    }
    console.log(video)
    video.load();

    document.addEventListener("DOMContentLoaded", () => {
      funcLoad()
      video.play();
    });

  } else {

    document.addEventListener("DOMContentLoaded", () => {
      comMain()
      funcLoad()
    });

  }


})();
export default funcEntry;